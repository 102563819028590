import { template as template_805489db85df4c00a8f400de71602be5 } from "@ember/template-compiler";
const FKText = template_805489db85df4c00a8f400de71602be5(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
