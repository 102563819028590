import { template as template_f7a60dc5e6f0418b884b8bca7b731499 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_f7a60dc5e6f0418b884b8bca7b731499(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
